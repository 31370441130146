<template>

  <h1>SVG ANIMATION MASK AND CLIP-PATH & GSAP  </h1>
  <svg id="svgOne" viewBox="0 0 500 500">
    <defs>
      <clipPath id="myClip">
        <rect id="cpRect1" x="0" y="0" width="251"
              height="500" fill="white"/>
        <rect id="cpRect2" x="250" y="0" width="250"
              height="500" fill="white"/>
      </clipPath>
    </defs>
    <image class="image" clip-path="url(#myClip)" href="https://images.unsplash.com/photo-1613399422915-926fb2b96030?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" preserveAspectRatio="xMidYMid slice"></image>
  </svg>


  <svg id="svgTwo" viewBox="0 0 500 500">
    <defs>
      <mask id="myMask">
        <rect id="rect1" x="0" y="0" width="251"
              height="500" fill="white"/>
        <rect id="rect2" x="250" y="0" width="250"
              height="500" fill="white"/>
      </mask>
    </defs>
    <image class="image" mask="url(#myMask)" href="https://images.unsplash.com/photo-1613399422915-926fb2b96030?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" preserveAspectRatio="xMidYMid slice"></image>
  </svg>

</template>

<script>

import {gsap} from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger);


export default {
  name: "ScrollTriggerNr2",

  mounted() {

    var tlClipPath = gsap.timeline({
      scrollTrigger: {
        trigger: "#svgOne",
        markers: true,
        start: "top bottom",
        end: "center-=200px center",
        scrub: 1,
      }
    })
    var tlMask = gsap.timeline({
      scrollTrigger: {
        trigger: "#svgTwo",
        markers: true,
        start: "top bottom",
        end: "center-=200px center",
        scrub: 1.5,
      }
    })

    tlClipPath.from("#cpRect1", {
      scale: 0,
      duration: 1,
    }).from("#cpRect2", {
      scale: 0,
      duration: 1,
      transformOrigin: "50% 50%"
    }, "<")
    tlMask.from("#rect1", {
      opacity: 0,
      duration: 1,
    }).from("#rect2", {
      yPercent: -100,
      duration: 1,
    }, "<");

  }}

</script>

<style scoped>

#svgOne {
  width:60%;
  margin:0 auto;
  margin-top: 120vh;
  display:block;
}
#svgTwo {
  width:60%;
  margin:0 auto;
  margin-top: 30vh;
  display:block;
}
image {
  width:100%;
  height:100%;
}
body {
  height: 500vh;
}
h1{
  text-align:center;
  font-size:8rem;
  line-height:1;
}
</style>